.hud-main-menu-outer-container {
    position: absolute;
    height: 100%;
    width: 100%;
}

.hud-main-menu-button-container {
    width: 35%;
    height: 94%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 2vw;
}

