.form-container {
    height: 100%;
    padding: 0 20vw;
    margin: 0;
    background: rgba(0, 0, 0, 0.75);
}

.confirmation-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;

    height: 100%;
    padding: 0 20vw;
    margin: 0;
    background: rgba(0, 0, 0, 0.75);
}

.confirmation-screen-container .button-wrapper {
    width: auto;
}

.form-container form {
    position: relative;
    top: 2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 98vh;
    overflow-y: auto;
    z-index: 1;
}

.form-container .button-wrapper {
    width: auto;
    align-self: flex-end;
    margin-bottom: 2vh;
}

.question-container p {
    color: white;
    margin: 0 3vw;
}

.question-container {
    width: 100%;
    margin-bottom: 1.5vh;
    border-top: rgb(255, 255, 255) solid;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 60px;
    padding: 3vw 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 25vh;
}

.input-wrapper {
    width: fit-content;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.question-container input {
    width: 50vw;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-bottom: 0.8vh;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #790005;
}

input:focus + .slider {
    box-shadow: 0 0 1px #790005;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

input[type=checkbox] {
    width: fit-content;
}

label {
    color: whitesmoke;
}

input[type=range] {
    -webkit-appearance: none;
    margin: 18px 0;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
    height: 1vh;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: #d9576d;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #e41e1e;
    height: 1.5vw;
    width: 1.5vw;
    border-radius: 50%;
    background: #790005;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -0.6vh;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #d9576d;
}
input[type=range]::-moz-range-track {
    height: 1vh;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: #d9576d;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
}
input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #790005;
    cursor: pointer;
}
input[type=range]::-ms-track {
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: #d9576d;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
    background: #d9576d;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #790005;
    cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
    background: #d9576d;
}
input[type=range]:focus::-ms-fill-upper {
    background: #d9576d;
}