.hud-song-search-outer-container {
    position: absolute;
    height: 100%;
    width: 100%;
}

.hud-song-search-inner-container {
    margin: 0 auto;
    width: 50%;
    height: 100%;
}

.song-list-container {
    height: 85%;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.search-bar-container {
    height: 15%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

input[type=text] {
    width: 110vw;
    height: 5vh;
    background-color: rgba(255, 255, 255, 0.3);
    margin: 0.8vw;
    border-radius: 10px;
    border: solid transparent;
    border-width: 4px 0 4px 0;
    outline: none;
    transition: 0.8s all;
    padding: 0 0 0 1vw;
    color: black;
    font-size: 12pt;
    text-align: center;
}

input[type=text]:disabled {
    cursor: not-allowed;
    opacity: 0.2;
}

input::placeholder {
    color: rgba(0, 0, 0, 0.3);
}

input:focus {
    border-color: #05208b;
}

input:hover {
    border-color: rgba(5, 32, 139, 0.4);
}
