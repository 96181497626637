.song-container-small {
    height: 8vh;
    width: 60%;
    transition: all 0.3s;
    margin: 0 auto 0.8vw auto;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;

    background-color: rgba(126, 126, 126, 0.9);
    border-left: 10px inset;
    border-bottom: 10px solid;
}

.song-container-mini {
    height: 6vw;
    width: auto;
    padding-right: 1.2vw;
    margin: 0 auto 0.8vw auto;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;

    background-color: rgba(126, 126, 126, 0.9);
    border-left: 10px inset;
    border-bottom: 10px solid;
}

.song-container-mini .song-data-container .song-name {
    font-size: 1.8vh;
    white-space: nowrap;
    margin-block-end: 0.4vw;
}

.song-container-mini .song-data-container .song-artist {
    font-size: 0.9vh;
    white-space: nowrap;
    margin-block-start: 0;
}

.song-container-large {
    height: 30%;
    border-radius: 5px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0 8vw 0 2vw;

    background-color: rgba(126, 126, 126, 0.9);
    border-left: 10px inset;
    border-bottom: 10px solid;
}

.song-container-large .song-data-container .song-name {
    font-size: 3vh;
    height: fit-content;
    white-space: nowrap;
    margin-block-end: 0.4vw;
}

.song-container-large .song-data-container .song-artist {
    font-size: 2.1vh;
    white-space: nowrap;
    margin-block-start: 0;
    color: #2f2f2f;
}

.song-container-small:hover {
    background-color: rgb(32, 109, 217);
    border-left: 4px solid;
    border-bottom: 4px solid;
}

/* It's important that all of these elements here can be
 * applied to other versions of the song container
 */
.song-data-container {
    height: 65%;
    margin-left: 1.5vw;
    font-family: "Source Sans Pro", sans-serif;
}

.song-image {
    height: 80%;
    width: auto;
    margin-left: 0.8vw;
}

.song-name {
    font-size: 2vh;
    white-space: nowrap;
    margin: 0;
    height: 60%;
    color: #000000;
}

.song-artist {
    font-family: Roboto, sans-serif !important;
    font-size: 1.2vh;
    white-space: nowrap;
    margin: 0;
    color: #1b1b1b;
}
