/* HUD Button Styles */

/* Main Menu Buttons */
.hud-button-small {
    width: 18vw;
    border-radius: 0.2vh;
    font-size: 2.2vh;
    margin-top: 0.5vh;
    border-right: none;
    border-top: none;
    height: 8vh;
}

.hud-button-small-pressed {
    margin-top:  0.6vh;
    height:  7.5vh;
}

.hud-button-enter {
    width: 10vw;
    height: 5.5vh;
    margin: 0.8vw;
    padding: 0;
    font-size: 2.0vh;
    border-radius: 10px;
    border: solid 2px darkgray;
    outline: none;
    transition: 0.2s all;
}

.pressed {
    background-color: rgb(32, 109, 217) !important;
    border-bottom:  solid 4px !important;
    border-left:  solid 4px !important;
}

.hud-button-enter-pressed {
    margin-top:  0.6vh;
    height:  5.8vh;
}

.hud-button-return {
    position: absolute;
    width: 8vh;
    height: 8vh;
    font-size: 3vw;
    bottom: 4vw;
    left: 10vw;
    border-radius: 50%;
}

.hud-button-return img {
    height: 70%;
    width: auto;
    position: absolute;
    top: 15%;
    left: 15%;
}

.hud-button-return-pressed {
    width: 8vh;
    height: 8vh;
}

.button-wrapper {
    width: 100%;
}

/* Generic button style */
.hud-button {
    cursor: pointer;
    transition: all 0.4s;
    outline: none;
    border-color: #5a5a5a;
    background-color: #b6b6b6;
    border-left: 10px inset #afafaf;
    border-bottom: 10px solid;
    font-family: "Source Sans Pro", sans-serif;
}

.hud-button:disabled {
    cursor: not-allowed;
    border-color: #2f2f2f;
    background-color: #4d4d4d;
    border-left: 4px inset #1f1f1f;
    border-bottom: 4px inset #1f1f1f;
    color: black;
    opacity: 0.85;
}

.spotify-button {
    background-color: #1DB954 !important;
    border-color: #062411;
    border-left: 10px inset #1DB954;
}
