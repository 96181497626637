.score-container {
    display: flex;
    align-items: center;
    border-color: rgb(32, 109, 217);
    background-color: rgb(32, 109, 217);
    border-left: 10px inset blue;
    border-bottom: 10px solid;
    height: 7vh;
    width: fit-content;
    padding: 0 2vw;
    font-family: "Source Sans Pro", sans-serif;
}

.score-container h2 {
    font-size: 1.4vw;
    color: #1d1d1d;
}

.score-container h1 {
    font-size: 1.8vw;
    margin-left: 1.5vw;
}
