.song-progress-bar-container {
    position: absolute;
    width: 100%;
}

#bar-wrapper {
    height: 2vw;
}

#progress-bar {
    height: 50%;
    background-color: rgba(0, 0, 76, 0.9);
    transition: 3.5s all linear;
}

.progress-bar-song-icon {
    position: fixed;
    left: 3vw;
    top: 3vw;
}
