.message-overlay-outer-container {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(13, 13, 13, 0.9);
    transition: 1s;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.message-overlay-body-container {
    display: inherit;
    flex-direction: inherit;
    justify-content: left;
    width: 80vw;
    max-height: 80vh;
    overflow: auto;
}

.message-overlay-body-container h1 {
    color: white;
    font-size: 5vh;
}

.message-overlay-body-container h2 {
    color: #cecece;
    font-size: 2.5vh;
}

.message-overlay-body-container p {
    color: #9a9a9a;
    font-size: 14pt;
}

.message-overlay-body-container a {
    color: #206dd9;
    font-size: 14pt;
}

.message-overlay-body-container a:hover {
    color: #5eaeff;
    font-size: 14pt;
}
